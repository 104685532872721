@import "minima";

/*Use a media query to add a breakpoint at 600px:*/
@media (max-width: 319px) {
  .home ul.pager li.apart {
    display: none;
  }
}

@media (max-width: 750px) {
  .sidebar {
    width: 100%;
    height: fit-content;
    margin: auto;
    float: none;
    border-top: 1px solid #e8e8e8;
    border-left: 0px;
    padding-left: 0px;
    padding-top: 20px;
    
    iframe#youtube_video {
  	  min-width: 200;
  	  min-height: 113;
    }
  }
  .home {
    
    float: none;
  }
  
  div#instafeed {
    width: fit-content;
  }
  
  .insta-image {
    max-width: 150px;
    max-height: 150px;
    width: 23%;
    height: 23%;
  }
}

@media (min-width: 751px) {
  .sidebar {
    width: 208px;
    float: right;
    height: 100%;
    min-height: 400px;
    border-top: 0px;
    border-left: 1px solid #e8e8e8;
    padding-left: 20px;
    padding-top: 0px;
  }
  
  .home {
    float: left;
    max-width: 500px;
  }
  
  .insta-image {
    max-width: 100px;
    max-height: 100px;
  }
}


.home {
  margin: auto;

  ul.pager { 
    text-align: center; 
    list-style: none; 
    margin: auto;
    overflow: auto;
    white-space: nowrap;
  }
  ul.pager li {
    display: inline-block;
    border: 1px solid #e8e8e8; 
    margin: 2px;

    a {
        display: inline-block;     
        position: relative;    
        z-index: 1;     
        padding: 10px;    
        
        svg {
          fill: currentColor;
          margin-bottom: -3px
        }
    }
  }
  .selected{  
    background-color: #00aba9; 
    a {
      color: #fff;
    }
  }
}

.sidebar {
  float: center;
  position: relative;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  
  
  iframe#youtube_video {
  	width: 200;
  	height: 113;
  }
  
  div#instafeed {
    text-align: center;
  }
  
}

.insta-follow {
  margin: 10px;
  text-align: center;

  a:hover {
    text-decoration:none;
  }

  .insta-follow-btn {
    padding: 0 8px;
    cursor: pointer;
    background: #3897f0;
    border-color: #3897f0;
    color: #fff;
    -webkit-appearance: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    align-items: flex-start;
    text-align: center;
    box-sizing: border-box;
    padding: 2px 6px 3px;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    margin: 0em;
    
    font: 400 11px system-ui;
    -webkit-writing-mode: horizontal-tb;
  }
  
  .insta-follow-txt {
    display: inline;
    font-size: 14px;
  }
}
  

  
  
.insta-image {
  border: 2px solid white;
}

.youtube-subscribe {
  text-align: center;
  
  .youtube-subscribe-txt {
    font-size: 14px;
    display: inline;
    position: relative;
    bottom: 5px;
    
    p {
      
      margin-bottom: 0px;
    }
  }
  
  div#yt-subscribe {
    display: inline;
  }

}

.site-header {
  background: #00aba9;
  border-top: none;
  .site-title {
    color: white;
  }
}

@media screen and (min-width: 601px) {
  .site-header {
    .site-nav {
      .page-link {
        color: white;
      }
    }
  }
}

.post-header {
  .post-title {
    color: #007b7a;
  }
}


ul.post-list {
  li {
    h2 {
      margin-bottom: 5px;
    }
  }
}

img.post-image {
  height: auto;
}


/** 
 * Post banner
 */
article.post {
  img.post-banner {
    height: auto;
    display: block;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}

img.inpost {
  height: auto;
	display: block;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}


/**
 * Links
 */
a {
  color: #007b7a;
  text-decoration: none; }
  a:visited {
    color: #007b7a; }
  a:hover {
    color: #111;
    text-decoration: none; }
  .social-media-list a:hover {
    text-decoration: none; }
    .social-media-list a:hover .username {
      text-decoration: underline; }

/**
 * Tags
 */
 
/* In the tags-expo */

div.tags-expo {
  margin-top: -15px;

  div.tags-expo-list {
    margin-bottom: 20px;
    
    a.post-tag {
      font-size: 14px;
    }
  }
  
  div.tags-expo-section {
    h2 {
      margin-bottom: 5px;
      font-size: 22px;
    }

    .posts-section {
      margin-bottom: 25px;

      summary {
        color: #007b7a;
      }
      details[open] summary {
        margin-bottom: 10px;
      }
       
      li {
        margin-bottom: 5px;      
      
        span.post-meta {
          font-size: 12px;
        }
        
        h2 {
          a.post-link {
            font-size: 18px;
          }
        }
      }
    
      
    }
  }
}
 
/* In the post list */
ul.post-list {
  span.post-meta {
    
  
    span.tags{
      position: relative;
      bottom: 2px;
      margin-left: 5px;
      
      a.post-tag{
        font-size: 10px;
      }
    }
  }
}
 
/* above each post */
p.post-meta {
  span.tags {
    margin-left: 10px;
  }
} 

p.post-meta-below {
  text-align: center;
}

a.post-tag:hover{
  background: white;
  color: #828282;
}
  
a.post-tag{
  background: #00aba9;
  border: 1px solid #007b7a;
  border-radius: 5px;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

